<template>
  <div>
    <loading v-if="isLoading" />
    <div v-else>
      <div
        v-for="(item, index) in data"
        :key="index"
        class="row"
      >
        <div class="col-12">
          <h1 class="mb-2">
            {{ flagNames[index] }} Statistic
          </h1>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-8">
              <b-card
                v-if="chartData"
                no-body
              >
                <!-- title and dropdown -->
                <b-card-header class="pb-0">
                  <b-card-title>{{ flagNames[index] }} Status</b-card-title>
                </b-card-header>
                <!--/ title and dropdown -->

                <b-card-body>
                  <b-row>
                    <b-col
                      sm="2"
                      class="d-flex flex-column flex-wrap text-center"
                    >
                      <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
                        {{ item.total_ticket }}
                      </h1>
                      <small>Tickets</small>
                    </b-col>

                    <!-- chart -->
                    <b-col
                      sm="10"
                      class="d-flex justify-content-center"
                    >

                      <!-- apex chart -->
                      <vue-apex-charts
                        type="radialBar"
                        height="270"
                        :options="chartData.supportTrackerRadialBar.chartOptions"
                        :series="[item.finish_percentage]"
                      />
                    </b-col>
                  <!--/ chart -->
                  </b-row>

                  <!-- chart info -->
                  <div class="d-flex justify-content-between">
                    <div
                      v-for="(status, i) in item.statuses"
                      :key="i"
                      class="text-center"
                    >
                      <b-card-text class="mb-50">
                        {{ status.name }}
                      </b-card-text>
                      <span class="font-large-1 font-weight-bold">{{ status.total_ticket }}</span>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </div>
            <div class="col-4">
              <b-card
                v-if="dataAverageTime[index]"
                no-body
              >
                <b-card-header>
                  <h4 class="mb-0">
                    Performance Overview
                  </h4>
                  <b-card-text class="font-medium-5 mb-0">
                    <feather-icon
                      icon="HelpCircleIcon"
                      size="21"
                      class="text-muted cursor-pointer"
                    />
                  </b-card-text>
                </b-card-header>

                <b-row class="text-center mx-0">
                  <b-col
                    cols="12"
                    class="border-top border-right d-flex align-items-between flex-column py-1"
                  >
                    <b-card-text class="text-muted mb-0">
                      Average Time
                    </b-card-text>
                    <h3 class="font-weight-bolder mb-0">
                      {{ dataAverageTime[index].average_time }}
                    </h3>
                  </b-col>
                </b-row>

                <b-row class="text-center mx-0">
                  <b-col
                    cols="6"
                    class="border-top border-right d-flex align-items-between flex-column py-1"
                  >
                    <b-card-text class="text-muted mb-0">
                      Lowest Time
                    </b-card-text>
                    <h3 class="font-weight-bolder mb-0">
                      {{ dataAverageTime[index].min_time }}
                    </h3>
                  </b-col>

                  <b-col
                    cols="6"
                    class="border-top d-flex align-items-between flex-column py-1"
                  >
                    <b-card-text class="text-muted mb-0">
                      Highest Time
                    </b-card-text>
                    <h3 class="font-weight-bolder mb-0">
                      {{ dataAverageTime[index].max_time }}
                    </h3>
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios'
import Loading from '@core/loading/Loading.vue'
import VueApexCharts from 'vue-apexcharts'
import {
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'

const SUPER_ADMIN_LIST = ['NCT1509059', 'NCT2406030']
const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#5e5873'
const $goalStrokeColor2 = '#51e5a8'

export default {
  name: 'Dashboard',
  components: {
    Loading,
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,

  },
  data() {
    return {
      chartData: {
        newTicket: 0,
        openTicket: 0,
        responseTime: 0,
        totalTicket: 0,
        lastDays: ['Last 7 Days', 'Last 30 Days', 'Last 3 Months'],
        supportTrackerRadialBar: {
          series: [80],
          chartOptions: {
            plotOptions: {
              radialBar: {
                size: 150,
                offsetY: 20,
                startAngle: -150,
                endAngle: 150,
                hollow: {
                  size: '65%',
                },
                track: {
                  background: '#fff',
                  strokeWidth: '100%',
                },
                dataLabels: {
                  name: {
                    offsetY: -5,
                    color: '#5e5873',
                    fontSize: '1rem',
                  },
                  value: {
                    offsetY: 15,
                    color: '#5e5873',
                    fontSize: '1.714rem',
                  },
                },
              },
            },
            colors: [$themeColors.danger],
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.5,
                gradientToColors: [$themeColors.primary],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
              },
            },
            stroke: {
              dashArray: 8,
            },
            labels: ['Completed Tickets'],
          },
        },
        goalOverviewRadialBar: {
          chart: {
            height: 245,
            type: 'radialBar',
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: [$goalStrokeColor2],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '77%',
              },
              track: {
                background: $strokeColor,
                strokeWidth: '50%',
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  color: $textHeadingColor,
                  fontSize: '2.86rem',
                  fontWeight: '600',
                },
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.success],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: 'round',
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
        },
      },
      totalTicket: {
        memo: 0,
        changeRequest: 0,
        bugs: 0,
      },
      data: [],
      dataMemo: [],
      dataChangeRequest: [],
      dataBugs: [],
      dataAverageTime: [],
      isLoading: false,
      isTimeLoading: false,
      flagNames: {
        1: 'Change Request',
        2: 'Memo',
        3: 'Bugs',
      },
      isManager: false,
      isAdmin: localStorage.getItem('admin'),
      nik: localStorage.getItem('nik'),
    }
  },
  mounted() {
    this.isManager = SUPER_ADMIN_LIST.includes(this.nik)

    this.init()
  },
  methods: {
    init() {
      this.getData()
      this.getAverageTime()
    },
    getData() {
      this.isLoading = true
      const params = {}
      if (Number(this.isAdmin) !== 1) {
        params.requested_by = this.nik
      } else if (!this.isManager) {
        params.assigned_to = this.nik
      }
      axios
        .get('/request/statistic', { params })
        .then(res => {
          this.data = res.data.data
          // // assign to dataMemo where flag is 1
          // this.dataMemo = res.data.data.filter(item => Number(item.flag) === 2)
          // this.totalTicket.memo = this.dataMemo.reduce((acc, item) => acc + Number(item.status_count), 0)
          // this.totalTicket.memoCompletedPercentage = this.dataMemo.reduce((acc, item) => (acc + Number(item.status_count), 0) / this.totalTicket.memo) * 100
          // // assign to dataChangeRequest where flag is 2
          // this.dataChangeRequest = res.data.data.filter(item => Number(item.flag) === 1)
          // this.totalTicket.changeRequest = this.dataChangeRequest.reduce((acc, item) => acc + Number(item.status_count), 0)
          // this.dataBugs = res.data.data.filter(item => Number(item.flag) === 3)
          // this.totalTicket.bugs = this.dataBugs.reduce((acc, item) => acc + Number(item.status_count), 0)
        })
        .catch(err => {
          // console.log(err)
        }).finally(() => {
          this.isLoading = false
        })
    },
    getAverageTime() {
      this.isTimeLoading = true
      const params = {}
      if (Number(this.isAdmin) !== 1) {
        params.requested_by = this.nik
      } else if (!this.isManager) {
        params.assigned_to = this.nik
      }
      axios
        .get('/request/average-time', { params })
        .then(res => {
          this.dataAverageTime = res.data.data
        })
        .catch(err => {
        // console.log(err)
        }).finally(() => {
          this.isTimeLoading = false
        })
    },
  },

}
</script>
